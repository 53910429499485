import create from "zustand";
import { devtools } from "zustand/middleware";
import AWN from "awesome-notifications";

import useUIStore from "./uiStore";
import client from "../lib/client";
import { silageTicketsUrl } from "../lib/endpoints";
import { setCropType, setBagName, getSilageTicketQueue, AddSilageTicketToQueue, RemoveSilageTicketFromQueue } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useSilageTicketStore = (set) => ({
  silageTickets: [],
  silageTicketQueue: [],
  loading: false,
  hasErrors: false,
  //isQueueTicket tracks if this create request is being performend on a ticket in the queue
  createSilageTicket: async (values, isQueueTicket = false) => {
    set(() => ({ loading: true }));
    try {
      setCropType(values.crop_type);
      setBagName(values.bag_name);

      if (values.load_size !== "" && values.load_size !== "other") {
        values.tare_weight = 0;
      }

      const response = await client.post(silageTicketsUrl, { silage_ticket: { ...values }, bag_name: values.bag_name });
      if (response.data.success) {
        if (isQueueTicket) {
          RemoveSilageTicketFromQueue(values);

          new AWN().info("Silage ticket created from queue", {
            position: "top-right",
            durations: { info: 5000 },
            labels: { info: "Queued ticket created!" },
            icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
          });
        } else {
          new AWN().info("", {
            position: "top-right",
            durations: { info: 5000 },
            labels: { info: "Silage ticket created" },
            icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
          });
        }

        set(() => ({ loading: false }));
        useUIStore.setState({ showSilageTruckModal: false, showSilageChopperModal: false, showSilageBaggerModal: false, loading: false });
      } else {
        set(() => ({ loading: false }));
        useUIStore.setState({ showSilageTruckModal: false, showSilageChopperModal: false, showSilageBaggerModal: false, loading: false });

        // AddSilageTicketToQueue({ ...values, uid: Date.now() });

        new AWN().info("There was an error when submitting this ticket. Please try again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Error creating ticket" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      }
    } catch (err) {
      if (!isQueueTicket && err && err.message && err.message === "Network Error") {
        // console.log("NETWORK ERROR");
        // If this is a queued ticket, do not add it back to the queue
        AddSilageTicketToQueue({ ...values, uid: Date.now() });

        new AWN().info("There was a network error when submitting this ticket. It was added to the queue and will be tried again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Ticket added to queue" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });

        useUIStore.setState({ showSilageTruckModal: false, showSilageChopperModal: false, showSilageBaggerModal: false, loading: false });
      } else if (!isQueueTicket) {
        new AWN().info("There was an error when submitting this ticket. Please try again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Error creating ticket" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      }
    }
  },
});

export const processSilageTicketQueue = async () => {
  const queue = getSilageTicketQueue();
  if (queue.length > 0) {
    await useSilageTicketStore.getState().createSilageTicket(queue[0], true);
  }
  setTimeout(processSilageTicketQueue, 60000);
};

useSilageTicketStore = devtools(useSilageTicketStore, { name: "SilageTicketStore" });

export default useSilageTicketStore = create(useSilageTicketStore);
