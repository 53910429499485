export default window.localStorage;

const authDeviceId = "pipe-device-id";
const cropType = "pipe-dcrop-type";
const bagName = "pipe-dbag-name";
const lastField = "pipe-last-field";
const lastDumpLocation = "pipe-last-dump-location";
const tareWeight = "pipe-tare-weight";
const otherLoadSizeUsed = "pipe-other-load-size";
const mileage = "pipe-mileage";
const lastLocation = "pipe-last-location";
const cutNumber = "pipe-cut-number";
const pitNumber = "pipe-pit-number";
const lastErrorTime = "pipe-last-error-time";
const lastTemperatureLogTime = "pipe-last-temperature-log-time";
const scaleWeight = "pipe-scale-weight";
const reloadTimestamp = "pipe-reload-timestamp";
const textSize = "pipe-text-size";
const locationStatus = "pipe-location-status";
const locationTimestamp = "pipe-location-timestamp";
const showFields = "pipe-show-fields";
const showWeatherPins = "pipe-show-weather-pins";
const silageTicketQueue = "pipe-silage-ticket-queue";
const gasTicketQueue = "pipe-gast-ticket-queue";
const dumpTicketQueue = "pipe-dump-ticket-queue";

// Device ID
export const getDeviceId = () => {
  return window.localStorage.getItem(authDeviceId);
};

export const setDeviceId = (deviceId) => {
  window.localStorage.setItem(authDeviceId, deviceId);
};

export const removeDeviceId = () => {
  window.localStorage.removeItem(authDeviceId);
};

// Crop Type
export const getCropType = () => {
  return window.localStorage.getItem(cropType);
};

export const setCropType = (selectedCropType) => {
  window.localStorage.setItem(cropType, selectedCropType);
};

export const removeCropType = () => {
  window.localStorage.removeItem(cropType);
};

// Bag Name
export const getBagName = () => {
  return window.localStorage.getItem(bagName);
};

export const setBagName = (enteredBagName) => {
  window.localStorage.setItem(bagName, enteredBagName);
};

export const removeBagName = () => {
  window.localStorage.removeItem(bagName);
};

// Last Field
export const getLastField = () => {
  return window.localStorage.getItem(lastField);
};

export const setLastField = (enteredLastField) => {
  window.localStorage.setItem(lastField, enteredLastField);
};

export const removeLastField = () => {
  window.localStorage.removeItem(lastField);
};

// Last Dump Location
export const getLastDumpLocation = () => {
  return window.localStorage.getItem(lastDumpLocation);
};

export const setLastDumpLocation = (enteredLastDumpLocation) => {
  window.localStorage.setItem(lastDumpLocation, enteredLastDumpLocation);
};

export const removeLastDumpLocation = () => {
  window.localStorage.removeItem(lastDumpLocation);
};

// Tare Weight
export const getTareWeight = () => {
  return window.localStorage.getItem(tareWeight);
};

export const setTareWeight = (val) => {
  window.localStorage.setItem(tareWeight, val);
};

export const removeTareWeight = () => {
  window.localStorage.removeItem(tareWeight);
};

// Other load size used
export const getOtherLoadSizeUsed = () => {
  return window.localStorage.getItem(otherLoadSizeUsed);
};

export const setOtherLoadSizeUsed = (val) => {
  window.localStorage.setItem(otherLoadSizeUsed, val);
};

export const removeOtherLoadSizeUsed = () => {
  window.localStorage.removeItem(otherLoadSizeUsed);
};

// Mileage
export const getMileage = () => {
  return window.localStorage.getItem(mileage);
};

export const setMileage = (miles) => {
  window.localStorage.setItem(mileage, miles);
};

export const incrementMileage = (miles) => {
  window.localStorage.setItem(mileage, parseFloat(getMileage()) + miles);
};

export const removeMileage = () => {
  window.localStorage.removeItem(mileage);
};

// Last Location
export const getLastLocation = () => {
  return window.localStorage.getItem(lastLocation);
};

export const setLastLocation = (val) => {
  window.localStorage.setItem(lastLocation, val);
};

export const removeLastLocation = () => {
  window.localStorage.removeItem(lastLocation);
};

// Cut Number
export const getCutNumber = () => {
  return window.localStorage.getItem(cutNumber);
};

export const setCutNumber = (val) => {
  window.localStorage.setItem(cutNumber, val);
};

export const removeCutNumber = () => {
  window.localStorage.removeItem(cutNumber);
};

// Pit Number
export const getPitNumber = () => {
  return window.localStorage.getItem(pitNumber);
};

export const setPitNumber = (val) => {
  window.localStorage.setItem(pitNumber, val);
};

export const removePitNumber = () => {
  window.localStorage.removeItem(pitNumber);
};

// Last Error Time
export const getLastErrorTime = () => {
  return window.localStorage.getItem(lastErrorTime);
};

export const setLastErrorTime = (val) => {
  window.localStorage.setItem(lastErrorTime, val);
};

export const removeLastErrorTime = () => {
  window.localStorage.removeItem(lastErrorTime);
};

// Last Temperature Log Time
export const getTemperatureLogTime = () => {
  return window.localStorage.getItem(lastTemperatureLogTime);
};

export const setTemperatureLogTime = (val) => {
  window.localStorage.setItem(lastTemperatureLogTime, val);
};

export const removeTemperatureLogTime = () => {
  window.localStorage.removeItem(lastTemperatureLogTime);
};

// Scale Weight
export const getScaleWeight = () => {
  return window.localStorage.getItem(scaleWeight);
};

export const setScaleWeight = (val) => {
  var numericOnly = val.replace(/\D/g, "");
  window.localStorage.setItem(scaleWeight, parseFloat(numericOnly));
};

export const removeScaleWeight = () => {
  window.localStorage.removeItem(scaleWeight);
};

// Reload timestamp used to determine if the app reloaded itself.
// Used for displaying the disclaimer modal on reboot.
export const getReloadTimestamp = () => {
  return window.localStorage.getItem(reloadTimestamp);
};

export const setReloadTimestamp = (val) => {
  window.localStorage.setItem(reloadTimestamp, val);
};

export const removeReloadTimestamp = () => {
  window.localStorage.removeItem(reloadTimestamp);
};

// App text size
export const getTextSize = () => {
  return window.localStorage.getItem(textSize) || "";
};

export const setTextSize = (val) => {
  window.localStorage.setItem(textSize, val);
};

export const removeTextSize = () => {
  window.localStorage.removeItem(textSize);
};

// Location status
export const getLocationStatus = () => {
  return window.localStorage.getItem(locationStatus) || "";
};

export const setLocationStatus = (val) => {
  window.localStorage.setItem(locationStatus, val);
};

export const removeLocationStatus = () => {
  window.localStorage.removeItem(locationStatus);
};

// Location timestamp
export const getLocationTimestamp = () => {
  return window.localStorage.getItem(locationTimestamp) || "";
};

export const setLocationTimestamp = (val) => {
  window.localStorage.setItem(locationTimestamp, val);
};

export const removeLocationTimestamp = () => {
  window.localStorage.removeItem(locationTimestamp);
};

// Show new field assignments
export const getShowFields = () => {
  return window.localStorage.getItem(showFields) || "";
};

export const setShowFields = (val) => {
  window.localStorage.setItem(showFields, val);
};

export const removeShowFields = () => {
  window.localStorage.removeItem(showFields);
};

// Show weather pins
export const getShowWeatherPins = () => {
  return window.localStorage.getItem(showWeatherPins) === "true" ? true : false;
};

export const setShowWeatherPins = (val) => {
  window.localStorage.setItem(showWeatherPins, val);
};

export const removeShowWeatherPins = () => {
  window.localStorage.removeItem(showWeatherPins);
};

// Silage Ticket Queue
export const getSilageTicketQueue = () => {
  const queue = window.localStorage.getItem(silageTicketQueue);
  return queue ? JSON.parse(queue) : [];
};

export const AddSilageTicketToQueue = (ticket) => {
  const queue = getSilageTicketQueue();
  queue.push(ticket);
  window.localStorage.setItem(silageTicketQueue, JSON.stringify(queue));
};

export const RemoveSilageTicketFromQueue = (addTicket) => {
  const queue = getSilageTicketQueue();
  const newQueue = queue.filter((ticket) => {
    return ticket.uid !== addTicket.uid;
  });

  window.localStorage.setItem(silageTicketQueue, JSON.stringify(newQueue));
};

export const removeSilageTicketQueue = () => {
  window.localStorage.removeItem(silageTicketQueue);
};

// Gas Ticket Queue
export const getGasTicketQueue = () => {
  const queue = window.localStorage.getItem(gasTicketQueue);
  return queue ? JSON.parse(queue) : [];
};

export const AddGasTicketToQueue = (ticket) => {
  const queue = getGasTicketQueue();
  queue.push(ticket);
  window.localStorage.setItem(gasTicketQueue, JSON.stringify(queue));
};

export const RemoveGasTicketFromQueue = (addTicket) => {
  const queue = getGasTicketQueue();
  const newQueue = queue.filter((ticket) => {
    return ticket.uid !== addTicket.uid;
  });

  window.localStorage.setItem(gasTicketQueue, JSON.stringify(newQueue));
};

export const removeGasTicketQueue = () => {
  window.localStorage.removeItem(gasTicketQueue);
};

// Dump Ticket Queue
export const getDumpTicketQueue = () => {
  const queue = window.localStorage.getItem(dumpTicketQueue);
  return queue ? JSON.parse(queue) : [];
};

export const AddDumpTicketToQueue = (ticket) => {
  const queue = getDumpTicketQueue();
  queue.push(ticket);
  window.localStorage.setItem(dumpTicketQueue, JSON.stringify(queue));
};

export const RemoveDumpTicketFromQueue = (addTicket) => {
  const queue = getDumpTicketQueue();
  const newQueue = queue.filter((ticket) => {
    return ticket.uid !== addTicket.uid;
  });

  window.localStorage.setItem(dumpTicketQueue, JSON.stringify(newQueue));
};

export const removeDumpTicketQueue = () => {
  window.localStorage.removeItem(dumpTicketQueue);
};

export const removeAllStorage = () => {
  removeDeviceId();
  removeCropType();
  removeBagName();
  removeLastField();
  removeTareWeight();
  removeMileage();
  removeLastLocation();
  removeCutNumber();
  removePitNumber();
  removeLastErrorTime();
  removeTemperatureLogTime();
  removeScaleWeight();
  removeReloadTimestamp();
  removeTextSize();
  removeLocationStatus();
  removeLocationTimestamp();
  removeShowFields();
  removeShowWeatherPins();
  removeSilageTicketQueue();
  removeGasTicketQueue();
  removeDumpTicketQueue();
};
