import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import alert, { confirm } from "./lib/alert";
import { getDeviceId, getScaleWeight, setLastLocation, setScaleWeight } from "./lib/storage";
import "./scss/site.scss";

import { processResetSpeed } from "./stores/locationStore";
import { processInitialCoverage, processCrewCoverage } from "./stores/crewStore";
import { processInitialWeatherStatuses } from "./stores/weatherStore";
import { processSilageTicketQueue } from "./stores/silageTicketStore";
import { processGasTicketQueue } from "./stores/gasTicketStore";
import { processDumpTicketQueue } from "./stores/dumpTicketStore";
import { processEquipmentAssignment, processEquipmentStatusQueue } from "./stores/equipmentStore";
// import { deletePin } from "./stores/customerMapPinStore";
import useAppStore from "./stores/appStore";
import useLocationStore from "./stores/locationStore";
import useCustomerMapPinStore from "./stores/customerMapPinStore";
import ErrorWrapper from "./ErrorWrapper";

window.alert = alert;
window.confirm = confirm;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorWrapper />
  </React.StrictMode>
);

window.placeMe = function (lng, lat, course = 0, connected = 1, speed = 0, distance = 0, updatePosition = true) {
  useAppStore.setState({ connected: connected });
  setLastLocation(JSON.stringify({ lng, lat }));
  useLocationStore.setState({
    myLocation: { lat, lng, course, connected, speed, distance, updatePosition },
    lastUpdateAt: Date.now(),
  });

  return getDeviceId();
};

window.deletePin = function (id) {
  window.confirm("Delete map pin?", () => useCustomerMapPinStore.getState().deletePin(id));
};

window.centerMapHere = function (lng, lat) {
  useLocationStore.setState({ centerMapCoords: { lat: lat, lng: lng } });
};

window.setScaleWeight = function (scaleWeight) {
  setScaleWeight(scaleWeight);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// call timer functions here
processResetSpeed();
processInitialCoverage();
processInitialWeatherStatuses();
// We were initially processing the inital coverage map fetch and the regular
// fetching and just running them separately. I have the initial fetch calling
// the regular one at this time. Just leaving this here if we want to walk it back.
// processCrewCoverage(false);
processSilageTicketQueue();
processGasTicketQueue();
processDumpTicketQueue();
processEquipmentAssignment();
processEquipmentStatusQueue();
