import { useEffect, useState, useContext } from "react";

import { MapContext } from "../components/MapLayer";
import useCrewStore from "../stores/crewStore";
import useUIStore from "../stores/uiStore";
import { feetToPixels } from "../lib/map";

export default function CoverageMap() {
  const mapContext = useContext(MapContext);

  const coverageMapData = useCrewStore((state) => state.coverageMapData);
  const showCoverageMap = useUIStore((state) => state.showCoverageMap);
  const mapZoomLevel = useUIStore((state) => state.mapZoomLevel);
  const [polylines, setPolylines] = useState([]);

  useEffect(() => {
    if (showCoverageMap) {
      polylines.forEach(function (line) {
        line.setMap(mapContext.map);
      });
    } else {
      polylines.forEach(function (line) {
        line.setMap(null);
      });
    }
  }, [showCoverageMap]);

  useEffect(() => {
    if (coverageMapData.length > 0) {
      let coverageMapCoords = [];
      for (let i = 0; i < coverageMapData.length; i++) {
        coverageMapCoords.push(window.google.maps.geometry.encoding.decodePath(coverageMapData[i]));
      }

      drawSnappedPolyline(coverageMapCoords);
    }
  }, [coverageMapData]);

  // useEffect(() => {
  //   polylines.forEach((polyline) =>
  //     polyline.setOptions({
  //       strokeWeight: feetToPixels(35, mapZoomLevel),
  //     })
  //   );
  // }, [mapZoomLevel]);

  // Draws the snapped polyline
  const drawSnappedPolyline = (coverageMapCoords) => {
    let polylineSet = [];

    for (let i = 0; i < coverageMapCoords.length; i++) {
      var snappedPolyline = new window.google.maps.Polyline({
        path: coverageMapCoords[i],
        icons: [
          {
            offset: "100%",
          },
        ],
        strokeColor: "blue",
        strokeWeight: 15,//feetToPixels(35, mapZoomLevel),
        strokeOpacity: 0.9,
      });
      polylineSet.push(snappedPolyline);
    }

    setPolylines([...polylines, ...polylineSet]);

    polylineSet.forEach(function (line) {
      if (showCoverageMap) {
        line.setMap(mapContext.map);
      } else {
        line.setMap(null);
      }
    });
  };

  return;
}
