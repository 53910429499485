import axios from "axios";
import { getDeviceId } from "./storage";

// Create axios instance
const instance = axios.create();
export const noIntercept = axios.create();

// Interceptors
instance.interceptors.request.use((request) => handleRequest(request));

instance.interceptors.response.use(
  (response) => handleSuccess(response),
  (error) => handleError(error)
);

instance.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.params["device"] = getDeviceId();
  return config;
});

// Interceptor handlers
const handleRequest = (request) => {
  // attach headers required for all authenticated endpoints
  // request.headers.common["access-token"] = getToken();
  // request.headers.common["client"] = getClient();
  // request.headers.common["uid"] = getUid();

  return request;
};

const handleSuccess = (response) => {
  return response;
};

const handleError = (error) => {
  try {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname !== "/login") {
        window.location.assign("/login");
      }
    }
  } catch (e) {
    console.log("Unable to handle error: ", e);
  }

  return Promise.reject({ ...error });
};

export default instance;
