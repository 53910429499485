import create from "zustand";
import { devtools } from "zustand/middleware";
import AWN from "awesome-notifications";

import useUIStore from "./uiStore";
import client from "../lib/client";
import { dumpTicketsUrl } from "../lib/endpoints";
import { getDumpTicketQueue, AddDumpTicketToQueue, RemoveDumpTicketFromQueue } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useDumpTicketStore = (set) => ({
  dumpTickets: [],
  dumpTicketQueue: [],
  loading: false,
  hasErrors: false,
  //isQueueTicket tracks if this create request is being performend on a ticket in the queue
  createDumpTicket: async (values, isQueueTicket = false) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(dumpTicketsUrl, { dump_ticket: { ...values } });
      if (response.data.success) {
        if (isQueueTicket) {
          // console.log("created queue ticket");
          // If this is a queued ticket, remove it from the ticket queue
          RemoveDumpTicketFromQueue(values);

          new AWN().info("Dump ticket created from queue", {
            position: "top-right",
            durations: { info: 5000 },
            labels: { info: "Queued ticket created!" },
            icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
          });
        } else {
          // console.log("created new ticket");
          new AWN().info("", {
            position: "top-right",
            durations: { info: 5000 },
            labels: { info: "Gas ticket created" },
            icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
          });
        }

        set(() => ({ loading: false }));
        useUIStore.setState({ showDumpTicketModal: false, loading: false });
      } else {
        // console.log("creation failed");
        useUIStore.setState({ showDumpTicketModal: false, loading: false });
        set(() => ({ loading: false }));

        new AWN().info("There was an error when submitting this ticket. Please try again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Error creating ticket" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      }
    } catch (err) {
      if (!isQueueTicket && err && err.message && err.message === "Network Error") {
        // If this is a queued ticket, do not add it back to the queue
        AddDumpTicketToQueue({ ...values, uid: Date.now() });
        set(() => ({ hasErrors: true, loading: false }));
        useUIStore.setState({ showDumpTicketModal: false, loading: false });

        new AWN().info("There was a network error when submitting this ticket. It was added to the queue and will be tried again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Ticket added to queue" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      } else if (!isQueueTicket) {
        new AWN().info("There was an error when submitting this ticket. Please try again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Error creating ticket" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      }
    }
  },
});

export const processDumpTicketQueue = async () => {
  const queue = getDumpTicketQueue();
  if (queue.length > 0) {
    await useDumpTicketStore.getState().createDumpTicket(queue[0], true);
  }
  setTimeout(processDumpTicketQueue, 60000);
};

useDumpTicketStore = devtools(useDumpTicketStore, { name: "DumpTicketStore" });

export default useDumpTicketStore = create(useDumpTicketStore);
