import create from "zustand";
import { devtools } from "zustand/middleware";
import AWN from "awesome-notifications";

import useUIStore from "./uiStore";
import client from "../lib/client";
import { gasTicketsUrl } from "../lib/endpoints";
import { getGasTicketQueue, AddGasTicketToQueue, RemoveGasTicketFromQueue } from "../lib/storage";

// let instead of const because we want to be able to use redux devtools
let useGasTicketStore = (set) => ({
  gasTickets: [],
  gasTicketQueue: [],
  loading: false,
  hasErrors: false,
  //isQueueTicket tracks if this create request is being performend on a ticket in the queue
  createGasTicket: async (values, isQueueTicket = false) => {
    set(() => ({ loading: true }));
    try {
      const response = await client.post(gasTicketsUrl, { gas_ticket: { ...values } });
      if (response.data.success) {
        if (isQueueTicket) {
          // console.log("created queue ticket");
          // If this is a queue ticket, remove it from the ticket queue
          RemoveGasTicketFromQueue(values);

          new AWN().info("Gas ticket created from queue", {
            position: "top-right",
            durations: { info: 5000 },
            labels: { info: "Queued ticket created!" },
            icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
          });
        } else {
          new AWN().info("", {
            position: "top-right",
            durations: { info: 5000 },
            labels: { info: "Gas ticket created" },
            icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
          });
        }

        set(() => ({ loading: false }));
        useUIStore.setState({ showGasTicketModal: false, loading: false });
      } else {
        // console.log("creation failed");
        useUIStore.setState({ showGasTicketModal: false, loading: false });
        set(() => ({ loading: false }));

        new AWN().info("There was an error when submitting this ticket. Please try again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Error creating ticket" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      }
    } catch (err) {
      if (!isQueueTicket && err && err.message && err.message === "Network Error") {
        // If this is a queue ticket, do not add it back to the queue
        // set((state) => ({ gasTicketQueue: [...state.gasTicketQueue, { ...values, uid: Date.now() }], loading: false }));
        AddGasTicketToQueue({ ...values, uid: Date.now() });
        set(() => ({ hasErrors: true, loading: false }));
        useUIStore.setState({ showGasTicketModal: false, loading: false });

        new AWN().info("There was a network error when submitting this ticket. It was added to the queue and will be tried again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Ticket added to queue" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      } else if (!isQueueTicket) {
        new AWN().info("There was an error when submitting this ticket. Please try again.", {
          position: "top-right",
          durations: { info: 5000 },
          labels: { info: "Error creating ticket" },
          icons: { prefix: "<i class=' big-icon fas", info: " fa-comments-alt", suffix: "'></i>" },
        });
      }
    }
  },
});

export const processGasTicketQueue = async () => {
  const queue = getGasTicketQueue();
  if (queue.length > 0) {
    await useGasTicketStore.getState().createGasTicket(queue[0], true);
  }
  setTimeout(processGasTicketQueue, 60000);
};

useGasTicketStore = devtools(useGasTicketStore, { name: "GasTicketStore" });

export default useGasTicketStore = create(useGasTicketStore);
